import "./AddChecksModal.scss"
import IconSearch from "../../assets/icons/icon_search.svg";
import {useEffect, useState} from "react";
import IconClose from "../../assets/icons/icon_close_black.svg";
import {useDispatch, useSelector} from "react-redux";
import {hideChecksModalAction} from "../../store/reducers/appReducer";
import CheckboxGosteh from "../CheckboxGosteh/CheckboxGosteh";

function AddChecksModal(){
    const {showChecksModal} = useSelector(store=>store.app)
    const {currentDataset,validations} = useSelector(store=>store.dataset)

    const dispatch = useDispatch()
    const [search,setSearch] = useState("")

    const [checks,setChecks] = useState([])
    function handleSearch(e){
        setSearch(e.target.value)
    }

    useEffect(()=>{
        let options = validations.filter(val => val.optional).map(val=>({
            name:val._id,
            label:val.name,
            value:false,
        }))
        setChecks(options)
    },[currentDataset,validations])
    

    function changeCheck(name,value){
        let new_checks = checks
        let index = new_checks.findIndex(p => p.name === name);
        new_checks[index].value = value
        setChecks(new_checks)
    }
    return (showChecksModal &&
        <div className="check_modal">
            <div className="window">
                <div className="close_check_modal">
                    <p className="gt_h3">Добавить проверку</p>
                    <button type="button" className="checks_close_btn" onClick={()=>dispatch(hideChecksModalAction())}><img src={IconClose} alt="close"/></button>
                </div>


                <div className="search">
                    <label htmlFor="search">
                        <img src={IconSearch} alt="search"/>
                    </label>
                    <input placeholder="Поиск проверки" id="search" className="gt_pt" value={search} onChange={(e)=>handleSearch(e)}/>
                </div>

                <div className="checks_block">
                    <p className="gt_pt gt_th">Доступные проверки</p>
                    {checks.map(check => (
                        <CheckboxGosteh
                            key={check.name}
                            label={check.label}
                            check_value={check.value}
                            onChange={changeCheck}
                            name={check.name}
                        />
                    ))}
                </div>

                <button type="button" className="gt_btn_primary">
                    <p className="gt_pt">Добавить</p>
                </button>
            </div>
        </div>
    )
}

export default AddChecksModal;
