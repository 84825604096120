import "./DataseLogs.scss"
import "../Notification/Notification.scss"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getDatasetLogsAction} from "../../store/actions/FolderAction";
import IconLogError from "../../assets/icons/icon_log_error.svg";
import IconLogSuccess from "../../assets/icons/icon_log_success.svg";
import IconLogInfo from "../../assets/icons/icon_log_info.svg";
import IconSearch from "../../assets/icons/icon_search.svg";
import {setDatasetLogsAction} from "../../store/reducers/datasetReducer";

function DataseLogs(){
    const dispatch = useDispatch()
    const {currentDataset,datasetLogs} = useSelector(store => store.dataset)

    useEffect(()=>{
        dispatch(setDatasetLogsAction([]))
        if(currentDataset.room_id){
            dispatch(getDatasetLogsAction(currentDataset._id,currentDataset.room_id))
        }
    },[currentDataset])

    const [types,setTypes] = useState([])
    const [search,setSearch] = useState("")

    function handleSelectType(type){
        if (types.includes(type)){
            setTypes(types.filter(t=>t !== type))
        }
        else {
            setTypes([...types,type])
        }
    }


    return (
        <div className="ds_logs">
            <div className="notification">
                <div className="notification_btn_group">
                    <div className="search">
                        <label htmlFor="search">
                            <img src={IconSearch} alt="search"/>
                        </label>
                        <input placeholder="Поиск" id="search" className="gt_pt" value={search} onChange={(e)=>setSearch(e.target.value)}/>
                    </div>
                    <div className="statuses">
                        <button type="button" className={`problem btn ${types.includes("error") ? "active"  : ""}`} onClick={()=>handleSelectType("error")}>
                            <img src={IconLogError} alt="log error"/>
                            <p className="gt_pt">Проблема</p>
                        </button>
                        <button type="button" className={`success btn ${types.includes("success") ? "active"  : ""}`} onClick={()=>handleSelectType("success")}>
                            <img src={IconLogSuccess} alt="log success"/>
                            <p className="gt_pt">Успех</p>
                        </button>
                        <button type="button" className={`information btn ${types.includes("information") ? "active"  : ""}`} onClick={()=>handleSelectType("information")}>
                            <img src={IconLogInfo} alt="log info"/>
                            <p className="gt_pt">Информация</p>
                        </button>
                    </div>
                </div>
                <div className="log_list">
                    {datasetLogs.filter(log=>log.text.toLowerCase().includes(search.toLowerCase())).filter(log => types.length === 0 || types.includes(log.status)).map((log,i)=>(
                        <div key={i} className="item">
                            <img src={log.status === "error" ? IconLogError :
                                log.status === "success" ? IconLogSuccess :
                                    IconLogInfo} alt="icon"/>

                            <p className="gt_pt gt_th time">{new Date(Number(log.time)).toLocaleTimeString("ru",{hour: '2-digit', minute: '2-digit'})}</p>
                            <p className="gt_pt text">{log.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DataseLogs;
