import "./RequirementsTableComponent.scss"
import LogErrorIcon from "../../../../../../../assets/icons/icon_log_error.svg";
import CheckIcon from "../../../../../../../assets/icons/icon_check.svg";
import ArrowUpIcon from "../../../../../../../assets/icons/icon_arrow_up.svg";
import ArrowTopIcon from "../../../../../../../assets/icons/icon_arrow_top.svg";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import DatasetChecks from "./DatasetChecks";
import IconPlus from "../../../../../../../assets/icons/icon_plus.svg";
import {showChecksModalAction} from "../../../../../../../store/reducers/appReducer";
import {getValidationsAction} from "../../../../../../../store/actions/datasetAction";


function RequirementsTableComponent() {

    const dispatch = useDispatch()

    const [detailProcessing,setDetailProcessing] = useState(false)
    const [showChecks,setShowChecks] = useState(false)
    const {currentDataset} = useSelector(store=>store.dataset)

    useEffect(()=>{
        dispatch(getValidationsAction(currentDataset._id))
    },[currentDataset])

    function handleShowChecks(){
        setDetailProcessing(false)
        setShowChecks(true)
    }

    function handleShowProcessing(){
        setDetailProcessing(true)
        setShowChecks(false)
    }

    return (
            <div>
                <div className="third_row">
                    <div className="processed">
                        <p className="gt_pt gt_th">Обработано:</p>
                        <div className="stats">
                            <img src={LogErrorIcon} alt="error"/>
                            <p className="gt_pt">0 / {currentDataset.info.counts}</p>
                        </div>
                    </div>
                </div>
                <div className="third_row fourth_row">
                    <div className="processed">
                        <p className="gt_pt gt_th">Проверки:</p>
                        <div className="stats">
                            <img src={LogErrorIcon} alt="error"/>
                            <p className="gt_pt">0 / {currentDataset.hasOwnProperty("validations") ? currentDataset.validations.length : "?"}</p>
                        </div>
                    </div>
                    <div className="check_gr">
                        <button type="button" className="back gt_btn_secondary">
                            <img src={CheckIcon} alt="check"/>
                            <p className="gt_ps gt_gb">Запустить проверку</p>
                        </button>
                        {!showChecks &&
                            <button type="button" className="back" onClick={handleShowChecks}>
                                <img src={ArrowUpIcon} alt="check"/>
                                <p className="gt_ps gt_gb">Подробнее</p>
                            </button>
                        }

                    </div>
                </div>
                {showChecks &&
                    <div className="checks_show_block">
                        <DatasetChecks />
                        {currentDataset.roles.includes("admin") &&
                            <button type="button" className="add_cheks" onClick={() => dispatch(showChecksModalAction())}>
                                <img src={IconPlus} alt="icon plus"/>
                                <p className="gt_pt gt_gb">Добавить проверку</p>
                            </button>
                        }
                        <div className="checks_hide">
                            <button type="button" className="hide_btn" onClick={() => setShowChecks(false)}>
                                <img src={ArrowTopIcon} alt="check"/>
                                <p className="gt_ps gt_gb">Скрыть</p>
                            </button>
                        </div>
                    </div>}

                <div className="third_row last_row">
                    <div className="processed">
                        <p className="gt_pt gt_th">Плановое количество файлов:</p>
                        <div className="stats">
                            <img src={LogErrorIcon} alt="error"/>
                            <p className="gt_pt">0 / {currentDataset.info.counts}</p>
                        </div>
                    </div>
                    {!detailProcessing &&
                        <button type="button" className="back" onClick={handleShowProcessing}>
                            <img src={ArrowUpIcon} alt="check"/>
                            <p className="gt_ps gt_gb">Подробнее</p>
                        </button>
                    }

                </div>
                {detailProcessing &&
                    <div className="detail_processing">
                        <div className="cat_block">
                            <div className="class_detail class_detail_header">
                                <p className="gt_pt gt_th ">Имя класса</p>
                                <p className="gt_pt gt_th center">Файлов</p>
                                <p className="gt_pt gt_th center">Разметок</p>
                            </div>
                        </div>

                        <div className="cat_block">
                            <div>
                                {
                                    currentDataset.info.categories.map(cat => (
                                        <div className="class_detail" key={cat.name + cat.color}>
                                            <p className="gt_pt">{cat.name}</p>
                                            <div className="class_detail_files"><p className="gt_pt item">0</p><p className="gt_pt gt_th item">/</p><p className="gt_pt gt_gr">20%</p></div>
                                            <div className="class_detail_files"><p className="gt_pt item">0</p><p className="gt_pt gt_th item">/</p><p className="gt_pt">20%</p></div>
                                        </div>
                                    ))
                                }
                            </div>

                            <button type="button" className="hide_btn" onClick={() => setDetailProcessing(false)}>
                                <img src={ArrowTopIcon} alt="check"/>
                                <p className="gt_ps gt_gb">Скрыть</p>
                            </button>
                        </div>
                    </div>
                }
            </div>
    )
}

export default RequirementsTableComponent;
