import { Route, Routes, Navigate, useLocation} from 'react-router-dom'
import './App.css';
import {ReactNotifications} from "react-notifications-component";
import 'react-notifications-component/dist/theme.css'
import Login from "./Login/Login";
import Room from "./Room/Room";
import Header from "./Header/Header";
import Menu from "./Menu/Menu";
import MyRoomAdministrator from "./MyRoom/Administrator/MyRoom/MyRoomAdministrator";
import CreateDatasetAdministrator from "./MyRoom/Administrator/CreateDataset/CreateDatasetAdministrator";
import Annotation from "./Annotation/Annotation";
import Notification from "./Notification/Notification";
import {useSelector} from "react-redux";
import FileUploadProgress from "./FileUploadProgress/FileUploadProgress";
import SuccessLoginPage from "./SuccessLoginPage/SuccessLoginPage";
import TimeSeries from "./TimeSeries/TimeSeries";

function App() {

  let location = useLocation();

  const {showNotification} = useSelector(store => store.app)
  const {userInfo} = useSelector(store=>store.user)
  return (
    <div className="app">
      <ReactNotifications />

      {location.pathname === "/login"  || location.pathname === "/success_login" || !userInfo ? "" : <Header />}
      {showNotification && <Notification />}
      <FileUploadProgress />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/login' element={<Login />} />
          <Route path='/room' element={<Room />} />
          <Route path='/my_room_admin' element={<MyRoomAdministrator />} />
          <Route path='/create_dataset' element={<CreateDatasetAdministrator />} />
          <Route path='/success_login' element={<SuccessLoginPage />} />
          <Route path='/annotation' element={<Annotation />} />
          <Route path='/ts_annotate' element={<TimeSeries />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    </div>
  );
}

export default App;
