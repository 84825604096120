import "./RoomStatus.scss";
import Waiting from "../../../assets/icons/Waiting.svg";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createRoom} from "../../../store/actions/datasetAction";




function RoomSearch () {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {currentDataset} = useSelector(store=>store.dataset)

    useEffect(()=>{
        if(currentDataset.hasOwnProperty("room_id")){
            if(currentDataset.room_id && currentDataset.room_id !== "null"){
                localStorage.setItem("room_id",currentDataset.room_id)
                navigate("/room?status=free")
            }else {
                dispatch(createRoom(currentDataset._id))
            }
        }else {
            dispatch(createRoom(currentDataset._id))
        }
    },[])

    return (
        <div className="room-status room-search">
            <div className="room-search_content">
                <img className="room-search_waiting-img" src={Waiting} alt="Логотип"/>
                <div className="room-text">
                    <p className="gt_h4">
                        Идёт поиск комнаты...
                    </p>
                    <p className="gt_pt">
                        Пожалуйста, подождите.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RoomSearch;
