import "./Login.scss";
import Gerb from "../../assets/icons/gerb.svg";
import GU_logo from "../../assets/icons/Госуслуги_лого.svg";
import {useDispatch, useSelector} from "react-redux";
import {getAuthUrlAction, refreshAccessTokenAction} from "../../store/actions/userAction";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";


function Login () {
    const navigate = useNavigate();

    const dispatch = useDispatch()
    const {userInfo} = useSelector(store=>store.user)

    function handleLogin(){
        dispatch(getAuthUrlAction())
    }

    useEffect(()=>{
        if (userInfo){
            dispatch(refreshAccessTokenAction())
            navigate("/my_room_admin");
        }
    },[])

    return (
        <div className="login">
            <div className="login_cart">
                <div className="login_content">
                    <div className="login_title">
                        <img className="login_logo" src={Gerb} alt="Логотип"/>
                        <div className="gt_h4 ">Федеральная платформа открытых данных</div>
                    </div>
                    <div className="gt_ps">Для работы в Системе необходимо авторизоваться посредством ввода логина и пароля учетной записи ЕСИА.</div>
                </div>
                <button type="button" onClick={handleLogin} className="gt_btn_gu">
                    <img className="login_btn_logo" src={GU_logo} alt="Госуслуги_лого"/>
                    <div className="gt_gu_btn">Войти через Госуслуги</div>
                </button>
            </div>
        </div>
    )
}

export default Login;
