import "./RoomStatus.scss";
import Free from "../../../assets/icons/free.svg";
import {useNavigate} from "react-router-dom";




function RoomFree () {
    const navigate = useNavigate();

    function handleWork(){
        navigate("/my_room_admin")
    }
    return (
        <div className="room-status room-free">
            <div className="room-free_content">
                <img className="room-free_free-img" src={Free} alt="Логотип"/>
                <div className="room-text">
                    <p className="gt_h4">
                        Комната готова
                    </p>
                    <p className="gt_pt">
                        Датасет успешно подключен к комнате
                    </p>
                </div>
            </div>
            <button className="gt_btn_primary" onClick={handleWork}>
                <span className="gt_pt">Приступить к работе</span>
            </button>
        </div>
    )
}

export default RoomFree;
