import "./RightTableComponent.scss"
import PublishIcon from "../../../../../../assets/icons/icon_publish.svg";
import EditIcon from "../../../../../../assets/icons/icon_edit.svg";
import DeleteIcon from "../../../../../../assets/icons/icon_delete.svg";
import ArrowIcon from "../../../../../../assets/icons/arrow_right.svg";
import {useState} from "react";
import RequirementsTableComponent from "./RequirementsTableComponent/RequirementsTableComponent";
import DescriptionTableComponent from "./DescriptionTableComponent/DescriptionTableComponent";
import {useDispatch, useSelector} from "react-redux";
import {showDeleteModalAction} from "../../../../../../store/reducers/appReducer";
import {Link} from "react-router-dom";
import DataseLogs from "../../../../../DataseLogs/DataseLogs";


function RightTableComponent({setShowDetail}){
    const {currentDataset} = useSelector(store=>store.dataset)

    const [activeZone,setActiveZone] = useState("requirements")

    const dispatch = useDispatch()

    function handleDelete(){
        dispatch(showDeleteModalAction())
    }


    return(
            <div className="right_col">
                <div className="top_row">
                    <div className="crud_btn">
                        {currentDataset.roles.includes("admin") &&
                            <>
                                <button type="button" className="gt_btn_primary btn" disabled={true}>
                                    <img src={PublishIcon} alt="publish"/>
                                    <p className="gt_ps">На публикацию</p>
                                </button>
                                <Link to="/create_dataset?action=edit" type="button" className="btn" >
                                    <img src={EditIcon} alt="edit"/>
                                </Link>
                                <button type="button" className="btn" onClick={handleDelete}>
                                    <img src={DeleteIcon} alt="edit"/>
                                </button>
                            </>}

                    </div>
                    <button type="button" className="btn" onClick={()=>setShowDetail(false)}>
                        <img src={ArrowIcon} alt="hide"/>
                    </button>
                </div>

                <div className="second_row">
                    <button type="button" className={`gt_pt part_item ${activeZone === "requirements" ? "part_item_active" : ""} `} onClick={()=>setActiveZone("requirements")}>Требования к публикации</button>
                    <button type="button" className={`gt_pt part_item ${activeZone === "description" ? "part_item_active" : ""} `} onClick={()=>setActiveZone("description")}>Описание</button>
                    <button type="button" className={`gt_pt part_item ${activeZone === "logs" ? "part_item_active" : ""} `} onClick={()=>setActiveZone("logs")}>Логи</button>
                </div>
                {
                    activeZone === "requirements" ? <RequirementsTableComponent /> :
                    activeZone === "description" ? <DescriptionTableComponent  /> :
                    activeZone === "logs" ? <DataseLogs  /> : ""
                }
            </div>

    )
}

export default RightTableComponent;
