import "../../AdminDatasetsComponent/RightTable/RightTableComponent.scss"
import "./FolderInfoComponent.scss"
import IconPlus from "../../../../../../assets/icons/icon_plus_wt.svg"
import IconError from "../../../../../../assets/icons/icon_log_error.svg"
import {useDispatch, useSelector} from "react-redux";
import {setFileUrlAction, showUploadModalAction} from "../../../../../../store/reducers/folderReducer";
import {useEffect} from "react";
import {downloadFilesAction, getFilesAction} from "../../../../../../store/actions/FolderAction";

function FolderInfoComponent(){
    const dispatch = useDispatch()

    const {currentFolder,files,fileUrl} = useSelector(store=>store.folder)
    const {currentDataset} = useSelector(store=>store.dataset)
    useEffect(()=>{
        if(currentFolder){
            dispatch(setFileUrlAction([]))
            dispatch(getFilesAction(currentDataset._id,currentFolder.info.name))
        }
    },[currentFolder])

    useEffect(()=>{
        dispatch(setFileUrlAction([]))
        if(currentFolder && files.length > 0) {
            if(files.length >= 3 && fileUrl.length === 0){
                files.slice(0,3).forEach(file =>dispatch(downloadFilesAction(currentDataset._id, currentFolder.info.name, file.name)))
            } else if (files.length <= 3 && fileUrl.length === 0){
                files.forEach(file =>dispatch(downloadFilesAction(currentDataset._id, currentFolder.info.name, file.name)))
            }
        }
    },[files,currentFolder,currentDataset])

    return(
        <div className="right_col folder">
            <div className="folder_row">
                {currentDataset.roles.includes("Поставщик") &&
                    <button type="button" className="gt_btn_primary" onClick={()=>dispatch(showUploadModalAction())}>
                        <img src={IconPlus} alt="plus"/>
                        <p className="gt_pt">Добавить файлы</p>
                    </button>
                }

            </div>
            {currentDataset.info.data_type === "photo" ?
                <div className="preview_row">
                    <p className="gt_h4">Превью:</p>
                    {fileUrl.length > 0 ?
                        <div className="preview_row_img_block">
                            {fileUrl.slice(0,3).map(((file,i) => <img key={i+"img"} src={URL.createObjectURL(file)} className="preview_row_img" alt="preview"/> ))}
                        </div>
                        :
                        <div className="preview_row_img_block">
                            <div className="preview_row_empty_img"/>
                            <div className="preview_row_empty_img"/>
                            <div className="preview_row_empty_img"/>
                        </div>
                    }
                </div>
                : ""
            }

            <div className="processed_row">
                <p className="gt_h4">Обработано:</p>
                {currentFolder ? <div className="gt_flex_row">{currentFolder.nfiles_annotated < currentFolder.nfiles ? <img src={IconError} alt="error"/> : ""}<p className="gt_pt">{currentFolder.nfiles_annotated} / {currentFolder.nfiles}</p></div> : <p className="gt_pt gt_th">Нет данных</p>}
            </div>

            <div className="processed_row">
                <p className="gt_h4">Дата последнего редактирования:</p>
                <p className="gt_pt gt_th">Нет данных</p>
            </div>
            <div className="processed_row">
                <p className="gt_h4">Классы датасета:</p>
                {currentFolder ?
                    <div className="detail_processing">
                        <div className="cat_block">
                            <div className="class_detail class_detail_header">
                                <p className="gt_pt gt_th ">Имя класса</p>
                                <p className="gt_pt gt_th center">Файлов</p>
                                <p className="gt_pt gt_th center">Разметок</p>
                            </div>
                        </div>

                        <div className="cat_block">
                            <div>
                                {
                                    currentDataset.info.categories.map(cat => (
                                        <div className="class_detail" key={cat.name + cat.color}>
                                            <p className="gt_pt">{cat.name}</p>
                                            <div className="class_detail_files"><p className="gt_pt item">0</p><p className="gt_pt gt_th item">/</p><p className="gt_pt gt_gr">20%</p></div>
                                            <div className="class_detail_files"><p className="gt_pt item">0</p><p className="gt_pt gt_th item">/</p><p className="gt_pt">20%</p></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    : <p className="gt_pt gt_th">Нет данных</p>}

            </div>


        </div>
    )
}

export default FolderInfoComponent;
