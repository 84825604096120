import "./DatasetList.scss"
import DatasetIcon from "../../../../../../assets/icons/folder.svg";
import FolderIcon from "../../../../../../assets/icons/file.svg";
import CopyIcon from "../../../../../../assets/icons/icon_copy.svg";
import NewDatasetIcon from "../../../../../../assets/icons/icon_new_dataset.svg";
import {enableFolderModeAction, setCurrentDatasetAction} from "../../../../../../store/reducers/datasetReducer";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentFolderAction} from "../../../../../../store/reducers/folderReducer";
import {useNavigate} from "react-router-dom";




function DatasetList ({datasets,activeDataset,setActiveDataset,type}) {

    const navigate = useNavigate();
    const {currentDataset} = useSelector(store=>store.dataset)

    const dispatch = useDispatch()

    function handleActive(ds){
        if(type==="dataset"){
            dispatch(setCurrentDatasetAction(ds))
            setActiveDataset(ds._id)
        }
        else {
            dispatch(setCurrentFolderAction(ds))
            setActiveDataset(ds.name)
        }

    }

    function handleDoubleClick(ds){
        if (type === "dataset"){
            dispatch(setCurrentDatasetAction(ds))
            dispatch(enableFolderModeAction())
            localStorage.setItem("current_dataset",JSON.stringify(ds))
            localStorage.setItem("folder_mode","true")
            navigate("/room?status=search")
        }
        else if (type === "folder"){
            if (currentDataset.roles.includes("admin") || currentDataset.roles.includes("Разметчик")){
                if (currentDataset.info.data_type === "photo"){
                    navigate("/annotation");
                }
                else{
                    navigate("/ts_annotate");
                }
            }
        }

    }


    return (
        <div className="dataset_list">
            {datasets.map(ds => (
                <button type="button"  className={`dataset_list_item ${activeDataset === ds._id ? "active" : ""}`} key={ds._id} onClick={()=>handleActive(ds)} onDoubleClick={()=>handleDoubleClick(ds)}>
                    <div className="dataset_list_name">
                        <img src={type === "dataset" ? DatasetIcon : FolderIcon} alt="Folder icon"/>
                        <p className="gt_pt dataset_list_name_text">{ds.info.name}</p>
                        {type === "dataset" ?
                        <div  className="dataset_list_name_image" onClick={() => {navigator.clipboard.writeText(ds._id)}}
                        ><img src={CopyIcon} alt="Copy icon"/></div>
                            : ""}
                    </div>
                    <div className="dataset_list_processing">

                    </div>
                    <div className="dataset_list_status">
                        {type === "dataset" ?
                            <>
                                <img src={NewDatasetIcon} alt="new dataset icon"/>
                                <p className="gt_pt">Новый датасет</p>
                            </>
                            : <p className="gt_pt gt_gr files_count">{ds.nfiles_annotated}/{ds.nfiles}</p>
                        }

                    </div>
                </button>
                )
            )}
        </div>
    )
}

export default DatasetList;
