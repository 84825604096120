import "./Notification.scss"
import {useDispatch, useSelector} from "react-redux";
import IconClose from "../../assets/icons/icon_close_black.svg";
import IconLogError from "../../assets/icons/icon_log_error.svg";
import IconLogSuccess from "../../assets/icons/icon_log_success.svg";
import IconLogInfo from "../../assets/icons/icon_log_info.svg";
import IconSearch from "../../assets/icons/icon_search.svg";
import {hideNotificationAction} from "../../store/reducers/appReducer";
import {useState} from "react";


function Notification (){
    const dispatch = useDispatch()

    const [search,setSearch] = useState("")

    const [types,setTypes] = useState([])

    const {logs} = useSelector(store => store.app)

    function handleSelectType(type){
        if (types.includes(type)){
            setTypes(types.filter(t=>t !== type))
        }
        else {
            setTypes([...types,type])
        }
    }

    return (
       <div className="notification">
           <button type="button" className="notification_close_btn" onClick={()=>dispatch(hideNotificationAction())}><img src={IconClose} alt="close"/></button>

           <p className="gt_h3">Уведомления</p>
           <div className="notification_btn_group">
                <div className="statuses">
                    <button type="button" className={`problem btn ${types.includes("error") ? "active"  : ""}`} onClick={()=>handleSelectType("error")}>
                        <img src={IconLogError} alt="log error"/>
                        <p className="gt_pt">Проблема</p>
                    </button>
                    <button type="button" className={`success btn ${types.includes("success") ? "active"  : ""}`} onClick={()=>handleSelectType("success")}>
                        <img src={IconLogSuccess} alt="log success"/>
                        <p className="gt_pt">Успех</p>
                    </button>
                    <button type="button" className={`information btn ${types.includes("information") ? "active"  : ""}`} onClick={()=>handleSelectType("information")}>
                        <img src={IconLogInfo} alt="log info"/>
                        <p className="gt_pt">Информация</p>
                    </button>
                </div>
               <div className="search">
                   <label htmlFor="search">
                        <img src={IconSearch} alt="search"/>
                   </label>
                   <input placeholder="Поиск" id="search" className="gt_pt" value={search} onChange={(e)=>setSearch(e.target.value)}/>
               </div>
           </div>
           <div className="log_list">
               {logs.filter(log=>log.text.toLowerCase().includes(search.toLowerCase())).filter(log => types.length === 0 || types.includes(log.status)).map((log,i)=>(
                   <div key={i} className="item">
                       <img src={log.status === "error" ? IconLogError :
                                 log.status === "success" ? IconLogSuccess :
                                 IconLogInfo} alt="icon"/>

                       <p className="gt_pt gt_th time">{new Date(log.time).toLocaleTimeString("ru",{hour: '2-digit', minute: '2-digit'})}</p>
                       <p className="gt_pt text">{log.text}</p>
                   </div>
               ))}
           </div>
       </div>
    )
}

export default Notification;
