import './Header.scss';
import {Link, NavLink} from "react-router-dom";
import Account from "../../assets/icons/account.svg";
import Logo from "../../assets/icons/logo_vector_header.svg";
import InfoIcon from "../../assets/icons/info_icon.svg";
import {useDispatch, useSelector} from "react-redux";
import {hideNotificationAction, showNotificationAction} from "../../store/reducers/appReducer";
import {logoutUserAction} from "../../store/actions/userAction";

function Header() {

    const dispatch = useDispatch()
    const {userInfo} = useSelector(store=>store.user)
    const {showNotification} = useSelector(store=>store.app)

    async function handleExit(){
        await dispatch(logoutUserAction())
        //localStorage.clear()
        //window.location.href = "/login";
    }

    function handleNotification(){
        if (showNotification){
            dispatch(hideNotificationAction())
        }else {
            dispatch(showNotificationAction())
        }
    }
    return (
        <nav className="header">
            <div className="header_brand">
                <div
                    className="header_logo-link"
                >
                    <img className="header_logo" src={Logo} alt="Логотип" />
                </div>
            </div>
            <div className="header_left_block">
                <div className="header_user-info">
                  <button type="button" onClick={handleExit} className="header_exit_btn">
                      <p className="gt_pt">Выйти</p>
                  </button>
                  <button type="button" className="header_info_btn" onClick={handleNotification}><img src={InfoIcon} alt="Логотип"/></button>
                </div>
                <div className="header_user-info">
                    <p className="gt_pt">
                        {userInfo ? userInfo.name : ""}
                    </p>
                    <img className="header_account-icon" src={Account} alt="Логотип" />
                </div>
            </div>
        </nav>
    );
}

export default Header;
