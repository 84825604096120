import "./DescriptionTableComponent.scss"
import ArrowUpIcon from "../../../../../../../assets/icons/icon_arrow_up.svg";
import ArrowTopIcon from "../../../../../../../assets/icons/icon_arrow_top.svg";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {downloadAnnotationAction, getAnnotationAction} from "../../../../../../../store/actions/datasetAction";
import JpgIcon from "../../../../../../../assets/icons/jpg_icon.svg";
import DocIcon from "../../../../../../../assets/icons/doc.svg";
import XlsIcon from "../../../../../../../assets/icons/xls.svg";
import PngIcon from "../../../../../../../assets/icons/png_icon.svg";
import PdfIcon from "../../../../../../../assets/icons/pdf.svg";
import UnknownIcon from "../../../../../../../assets/icons/unknown.svg";

function DescriptionTableComponent(){
    const [showDetail,setShowDetail] = useState(false)

    const {files,currentDataset} = useSelector(store=>store.dataset)

    const dispatch = useDispatch()

    useEffect(()=>{

        dispatch(getAnnotationAction(currentDataset._id))
    },[currentDataset])


    function handleDownload(file){
        dispatch(downloadAnnotationAction(currentDataset._id,file))
    }

    return (
            <div className="desc_block">
                <div className="markups">
                    <p className="gt_h4">Размечает:</p>
                    <p className="gt_pt">Константин Константинопольский</p>
                </div>

                <div className="description">
                    <p className="gt_h4">Общее описание:</p>
                    <div className="main_desc">
                        <div className="desc_data">
                            <div className="desc_data_item">
                                <p className="gt_pt gt_th">Наименование набора данных</p>
                                <p className="gt_pt ">{currentDataset.info.name}</p>
                            </div>
                            <div className="desc_data_item">
                                <p className="gt_pt gt_th">Владелец набора данных</p>
                                <p className="gt_pt ">{currentDataset.info.owner}</p>
                            </div>
                            {showDetail &&
                                <>
                                    <div className="desc_data_item">
                                        <p className="gt_pt gt_th">Описание</p>
                                        <p className="gt_pt ">{currentDataset.info.description}</p>
                                    </div>
                                    <div className="desc_data_item">
                                        <p className="gt_pt gt_th">Применение</p>
                                        <p className="gt_pt ">{currentDataset.info.usage}</p>
                                    </div>


                                    {files.length > 0 ?
                                        <div className="desc_data_item">
                                            <p className="gt_pt gt_th">Аннотация</p>
                                            {files.map(file =>
                                                <button type="button" onClick={() => handleDownload(file)} className="gt_pt annot_btn" key={file}>
                                                    <img src={file.split(".").slice(-1)[0] === 'jpg' || file.split(".").slice(-1)[0] === "jpeg" ? JpgIcon :
                                                        file.split(".").slice(-1)[0] === 'doc' || file.split(".").slice(-1)[0] === "docx" ? DocIcon :
                                                            file.split(".").slice(-1)[0] === 'xls' || file.split(".").slice(-1)[0] === "xlsx" ? XlsIcon :
                                                                file.split(".").slice(-1)[0] === 'png' ? PngIcon :
                                                                    file.split(".").slice(-1)[0] === 'pdf' ? PdfIcon : UnknownIcon} alt="icon"/>
                                                    <p className="gt_pt">{file}</p>
                                                </button>)}

                                        </div> : ""
                                    }



                                    <div className="desc_data_item">
                                        <p className="gt_pt gt_th">Тип данных</p>
                                        <p className="gt_pt ">{currentDataset.info.data_type === "photo" ? "Изображения" : "Времянные ряды"}</p>
                                    </div>
                                    <div className="desc_data_item">
                                        <p className="gt_pt gt_th">Роли</p>
                                        {currentDataset.info.roles.map(r => (
                                            <div className="roles_list" key={r.ogrn}>
                                                <p className="gt_pt">{r.role}</p>
                                                <p className="gt_pt">{r.ogrn}</p>
                                            </div>

                                        ))}
                                    </div>
                                    <div className="desc_data_item">
                                        <p className="gt_pt gt_th">Расположение</p>
                                        <p className="gt_pt ">{currentDataset._id}</p>
                                    </div>
                                    <div className="desc_data_item">
                                        <p className="gt_pt gt_th">Объем</p>
                                        <p className="gt_pt ">{currentDataset.info.counts}</p>
                                    </div>
                                </>
                            }
                        </div>
                        <button type="button" className="detail_btn" onClick={() => setShowDetail(!showDetail)}>
                            <img src={!showDetail ? ArrowUpIcon : ArrowTopIcon} alt="check"/>
                            <p className="gt_ps gt_gb">{!showDetail ? "Подробнее" : "Скрыть"}</p>
                        </button>
                    </div>
                </div>

            </div>
    )
}

export default DescriptionTableComponent;
