const SHOW_NOTIFICATION = "SHOW_LOADER"
const HIDE_NOTIFICATION = "HIDE_NOTIFICATION"
const SHOW_DELETE_MODAL = "SHOW_DELETE_MODAL"
const HIDE_DELETE_MODAL = "HIDE_DELETE_MODAL"
const SHOW_CHECKS_MODAL = "SHOW_CHECKS_MODAL"
const HIDE_CHECKS_MODAL = "HIDE_CHECKS_MODAL"
const ADD_LOGS = "ADD_LOGS"
const SET_DATASET_PAGE = "SET_DATASET_PAGE"

const initialState = {
    showNotification: false,
    logs:[],
    showDeleteModal:false,
    showChecksModal:false,
    datasetPage:0,
}

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return { ...state, showNotification: true }
        case HIDE_NOTIFICATION:
            return { ...state, showNotification: false}
        case SHOW_DELETE_MODAL:
            return { ...state, showDeleteModal: true}
        case HIDE_DELETE_MODAL:
            return { ...state, showDeleteModal: false}
        case SHOW_CHECKS_MODAL:
            return { ...state, showChecksModal: true}
        case HIDE_CHECKS_MODAL:
            return { ...state, showChecksModal: false}
        case ADD_LOGS:
            return { ...state, logs: [...state.logs, action.payload] }
        case SET_DATASET_PAGE:
            return { ...state, datasetPage: action.payload}
        default:
            return state
    }
}

export const showNotificationAction = () => ({ type: SHOW_NOTIFICATION })
export const hideNotificationAction = () => ({ type: HIDE_NOTIFICATION })
export const showDeleteModalAction = () => ({ type: SHOW_DELETE_MODAL })
export const hideDeleteModalAction = () => ({ type: HIDE_DELETE_MODAL })
export const showChecksModalAction = () => ({ type: SHOW_CHECKS_MODAL })
export const hideChecksModalAction = () => ({ type: HIDE_CHECKS_MODAL })
export const addLogsAction = (logs) => ({ type: ADD_LOGS, payload: logs })
export const setDatasetPageAction = (page) => ({ type: SET_DATASET_PAGE, payload: page })

