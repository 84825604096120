import "./DatasetChecks.scss"
import {useEffect, useState} from "react";
import CheckboxGosteh from "../../../../../../CheckboxGosteh/CheckboxGosteh";
import {useSelector} from "react-redux";

function DatasetChecks(){
    const {currentDataset,validations} = useSelector(store=>store.dataset)
    const [checks,setChecks] = useState([])

    useEffect(()=>{
        let list = currentDataset.validations.map(ds_val => (
            {
                name:ds_val._id,
                label:validations.filter(val => val._id === ds_val._id)[0].name,
                value:true,
            }
        ))
        setChecks(list)
    },[currentDataset,validations])



    function changeCheck(name,value){
        let new_checks = checks
        let index = new_checks.findIndex(p => p.name === name);
        new_checks[index].value = value
        setChecks(new_checks)
    }

    return(
        <div className="ds_checks_block">
            {checks.map(check => (
                <CheckboxGosteh
                    key={check.name}
                    label={check.label}
                    check_value={check.value}
                    onChange={changeCheck}
                    name={check.name}
                />
            ))}
        </div>
    )
}






export default DatasetChecks;
