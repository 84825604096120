const SET_DATASETS = "SET_DATASETS"
const SET_CURRENT_DATASET = "SET_CURRENT_DATASET"
const SET_FILES = "SET_FILES"
const ENABLE_FOLDER_MODE = "ENABLE_FOLDER_MODE"
const DISABLE_FOLDER_MODE = "DISABLE_FOLDER_MODE"
const SET_SANITIZERS = "SET_SANITIZERS"
const SET_DATASET_LOGS = "SET_DATASET_LOGS"
const SET_VALIDATIONS = "SET_VALIDATIONS"

let curDS = JSON.parse(localStorage.getItem("current_dataset"))
let folder_mode = localStorage.getItem("folder_mode") === "true"
const initialState = {
    datasets: [],
    currentDataset: curDS,
    files: [],
    folderMode:folder_mode,
    sanitizers:[],
    datasetLogs:[],
    validations:[],
}

export const datasetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATASETS:
            return { ...state, datasets: action.payload }
        case SET_CURRENT_DATASET:
            return { ...state, currentDataset: action.payload }
        case SET_FILES:
            return { ...state, files: action.payload }
        case ENABLE_FOLDER_MODE:
            return { ...state, folderMode: true }
        case DISABLE_FOLDER_MODE:
            return { ...state, folderMode: false }
        case SET_SANITIZERS:
            return { ...state, sanitizers: action.payload }
        case SET_DATASET_LOGS:
            return { ...state, datasetLogs: action.payload }
        case SET_VALIDATIONS:
            return { ...state, validations: action.payload }
        default:
            return state
    }
}

export const setDatasetsAction = (datasets) => ({ type: SET_DATASETS, payload: datasets })
export const setCurrentDatasetAction = (dataset) => ({ type: SET_CURRENT_DATASET, payload: dataset })
export const setFilesAction = (files) => ({ type: SET_FILES, payload: files })
export const enableFolderModeAction = () => ({ type: ENABLE_FOLDER_MODE})
export const disableFolderModeAction = () => ({ type: DISABLE_FOLDER_MODE})
export const setSanitizersAction = (sinit) => ({ type: SET_SANITIZERS,payload: sinit})
export const setDatasetLogsAction = (logs) => ({ type: SET_DATASET_LOGS,payload: logs})
export const setValidationsAction = (valid) => ({ type: SET_VALIDATIONS,payload: valid})

