import {Link} from "react-router-dom";
import IconPlus from "../../../../../assets/icons/icon_plus.svg";
import "../AdminDatasetsComponent/AdminDatasetsComponent.scss"
import {useSelector} from "react-redux";

function EmptyRoomAdministrator () {
    const {userInfo} = useSelector(store=>store.user)
    return (
        <div className="ad_mr">
            <div className="ad_mr_title">
                <p className="gt_h3">Моя комната</p>
                <div className="ad_mr_select">
                    <div className="gt_pt ad_mr_draft">Черновики</div>
                    <div className="gt_pt ad_mr_publish">Опубликованы</div>
                </div>
            </div>
            <p className="gt_pt gt_th ad_mr_text">Нет созданных датасетов. Добавьте новый или используйте готовый датасет в качестве шаблона. Все изменения будут храниться в разделе “Черновики”. </p>
            {userInfo.role === "admin" ?
                <div className="ad_mr_btn_group">
                    <Link to="/create_dataset" className="ad_mr_btn"><img src={IconPlus} alt="Logo"/><p className="gt_pt gt_gb">Создать датасет</p></Link>
                    <button className="ad_mr_btn"><img src={IconPlus} alt="Logo"/><p className="gt_pt gt_gb">Использовать шаблон</p></button>
                </div>
                : ""}

        </div>
    )
}

export default EmptyRoomAdministrator;
